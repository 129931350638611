/* Global Styles */
body {
  font-family: 'Poppins', sans-serif;
  color: #fff;
  /* background-color: #0d0d0d; */


}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins';
}

a {
  color: #00d4ff;
}

a:hover {
  color: #fff;
}

.me-auto.navbar-nav {
  display: flex;
  gap: 24px;
  margin: 0 auto;
}

.fixed-top {
  position: absolute !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.navbar {
  padding: 20px 0;
  background: transparent !important;
}

.navbar .nav-link {
  color: #fff;
}

.navbar .nav-link:hover {
  color: #00d4ff;
}

.navbar-brand {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}


/* Hero Section */
/* .hero-section {
  background: url('./images/bg.png') no-repeat center center;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #222;
  text-align: left;
  padding: 0 20px;
} */

.hero-section h1 {
  font-size: 50px;
  font-weight: 700;
}

.hero-section p {
  font-size: 1.2rem;
  color: #bfbfbf;
}

.hero-section .statistics {
  font-size: 1.2rem;
  margin-top: 20px;
  color: #00d4ff;
}

/* Services Section */

.service-box {
  padding: 28px;
  background-color: #222;
  border-radius: 52px;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0px 3px 4px #222222;
}

.service-box p {
  font-size: 1rem;
  color: #bfbfbf;
}

/* Why Choose Us Section */

.why-choose-us-section h2 {
  font-size: 40px;
  font-weight: 600;
}

.why-choose-us-section ul {
  list-style: none;
  padding: 0;
  font-size: 1.2rem;
  margin: 20px 0;
}

.why-choose-us-section ul li {
  margin-bottom: 15px;
}

/* Case Study Section */
.case-study-section {
  padding: 100px 0;

}

.case-study-section h2 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 50px;
}



/* Awards Section */

.award-box {
  padding: 30px 20px;
  background-color: #FFC93E;
  border-radius: 12px;
  transition: all 0.3s ease;
  text-align: center;
}

.award-box p {
  font-size: 1rem;
  color: #bfbfbf;
}

/* Testimonials Section */
.testimonials-section {
  padding: 100px 0 40px 0;

}
section.testimonials-section .row {
  margin: 0 auto;
}

section.testimonials-section h2 {
  text-align: left !important;
}

.clients-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clients-image {
  display: flex;
  gap: 20px;
  align-items: center;
}


section.testimonials-section .slick-slide {
  padding: 15px; 
}
section.testimonials-section .slick-prev:before{
  content: url('./images/testi-arrow-prev.png');
  background-repeat: no-repeat;
  background-size: cover;
}
section.testimonials-section .slick-next:before{
  content: url('./images/testi-arrow-prev.png');
  background-repeat: no-repeat;
  background-size: cover;
}
section.testimonials-section .slick-next {
  transform: rotate(180deg);
  top: unset;
  bottom: 180px;
}
section.testimonials-section .slick-prev {
  left: -5px;
  z-index: 1;
}
section.testimonials-section .slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  color: white;
  opacity: 1;
}
section.testimonials-section .slick-dots li.slick-active button:before {
  color: rgb(255, 201, 62);
}

.quote-image {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  opacity: 0.2;
}
.testimonial-box {
  padding: 30px;
  background: #0000004f;
  border-radius: 50px;
  margin: 0 15px; 
}
.testimonial-box p {
  color: #E0E5F3;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.stars svg {
  color: rgb(255, 201, 62);
}
.stars {
  display: flex;
  gap: 5px;
}
.testimonial-box p span {
  color: #FFC93E;
}
.testimonial-box .clients-sec p {
  margin-bottom: 0;
}



/* Contact Section */
input:focus {
  color: white !important;
}
.contact-section .form-control {
  border: none;
  border-bottom: 1px solid white;
  border-radius: 0px;
  height: 40px; /* Height to match input style */
  box-shadow: none;
  background: transparent;
  width: 70%;
  color: white;
}
.form-text.mt-3 p {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30.3px;
  text-align: left;
}
.custom-form {
  color: white;
  padding: 20px;
}

.custom-form .form-label {
  color: gray; /* Label color matching the image */
}
.custom-form .custom-button {
  border: none;
  padding: 10px  35px;
}

.custom-form .custom-button:hover {
  background-color: #e6b800; /* Darker yellow on hover */
}

/* Styling the 'Or' separator */
.custom-form .text-center p {
  margin-top: 8px;
  font-size: 16px;
  color: gray;
}

.contact-content {
  display: flex;
  align-items: baseline;
  padding: 0px 0px 30px 0px;
}
.about-history-section .row {
  align-items: center !important; 
}
.history-image img {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  border-radius: 30px ;
}

.history-image img.fade-out {
  opacity: 0;
}

.description-text {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.description-text.fade-out {
  opacity: 0;
}
p.history-description {
  background-image: linear-gradient(360deg, rgba(255, 255, 255, 0.3) 44.5%, #FFFFFF 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.years {
  border-left: 1px solid white;
  padding: 0px 0px 0px 10px;
  margin-top: 80px;
}
.arrows svg {
  color: white;
  width: 10px;
}
.years p {
  padding: 12px;
}

.arrows {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: -3px;
}

.arrows button {
  padding: 10px 0px;
}
.reach-image img {
  width: 60%;
}
.reach-image {
  margin-top: -100px;
}
.contact-section {
  padding: 100px 0;
}
.contact-section h2 {
  margin-bottom: 30px;
  font-family: Poppins;
  font-size: 78px;
  font-weight: 700;
  line-height: 94px;
  color: #32E0AE;
}
.contact-section .form-label {
  color: #bfbfbf;
  margin: 0;
}
section.contact-section .form-row1.row {
  align-items: end;
}
section.contact-section .col-md-6 div {
  display: inline-flex;
  width: -webkit-fill-available;
  align-items: end;
  justify-content: space-between;
}   
section.contact-section .col-md-5 div label {
  width: auto ;
}
.contact-section input:focus {
  background: transparent;
  outline: unset;
  box-shadow: unset;
}
@keyframes button-hover{
16.65% {
  transform: translateY(8px);
}
33.3% {
  transform: translateY(-6px);
}
49.95% {
  transform: translateY(4px);
}
66.6% {
  transform: translateY(-2px);
}
83.25% {
  transform: translateY(1px);
}
100% {
  transform: translateY(0);
}
}
/* Footer Section */
.footer {
  padding: 50px 0;
  background-color: #000;
  text-align: center;
  color: #bfbfbf;
}

.footer a {
  color: #00d4ff;
}

.footer a:hover {
  color: #fff;
}

/* src/App.css */
/* vision section starts */
.vision-section {
  padding: 50px 0px;
} 
.vision-box {
  padding: 0px 110px 0px 0px;
}
.vision-box h2.section-heading {
  color: #32E0AE;
  border-bottom: 1px solid white;
  width: 130px;
}
.vision-row-2 {
  padding: 50px 0px;
  text-align: center;
}
.vision-counter {
  background: linear-gradient(0deg, #121214, #121214), linear-gradient(0deg, #121214, #121214);
  padding: 40px 50px 40px 50px;
  border-radius: 50px;
}
.vision-counter h2.section-heading {
  text-align: center;
  margin-bottom: 0 !important;
  color: #32E0AE;
}
.vision-box p {
  background-image: linear-gradient(360deg, rgba(255, 255, 255, 0.3) 44.5%, #FFFFFF 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.straight-line {
  border-left: 1px solid rgba(255, 255, 255, 1);
  height: 40px;
  display: table;
  margin: 0 auto;
  margin-bottom: 10px;
}

/* vision section  ends*/
/* our values start here */
.our-values-section {
  padding: 50px 0px;
}

.our-values-section h2.section-heading {
  color: #32E0AE;
}
.numbers-box {
  padding: 20px 13px;
}
.values-number-box p {
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  display: flex;
}
.respect-image-wrapper img {
  width: 10%;
}
.respect-image-wrapper {
  width: 100%;
  margin-bottom: 10px;
}
.respect-section.row {
  padding: 50px 0px;
  align-items: center;
}
.respect-box p {
  background-image: linear-gradient(360deg, rgba(255, 255, 255, 0.3) 44.5%, #FFFFFF 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
/* our values ends here */
/* case study starts here  */
.case-study-page{
  background-image: url('./images/case-study-body.webp');
  background-size: cover; 
}
.expert-image {
  margin-bottom: 20px;
}
.expert-box h4 {
  font-family: 'Poppins';
  font-size: 25px;
  font-weight: 400;
  line-height: 44px;
  color: white;
}
.expertise-section {
  padding: 50px 0px;
}
.expertise-section .expert-row-1.row {
  padding: 50px 0px 0px 0px;
}
.expertise-section .col {
  padding: 0;
}
.expertise-section .border-left {
  border-left: unset !important;
}
.expertise-section .border-right {
  border-right: unset !important;
}
.expert-row-1.row .expert-box.text-center {
  padding: 30px 20px 20px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-top: unset;
}
.expert-row-2.row .expert-box.text-center {
  padding: 30px 20px 20px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-top: unset;
}
.expert-row-3.row .expert-box.text-center {
  padding: 30px 20px 20px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: unset;
}
.expert-image img {
  height: 60px;
}
.faqs-section h2 {
  text-transform: capitalize;
}
.faqs-section h2.section-heading.text-center.green {
  color: #32E0AE;
}
.faqs-section .accordion {
  margin-bottom: 20px;
}
.faqs-section button.accordion-button {
  background: rgba(50, 50, 50, 1);
  color: white;
}
.faqs-section .accordion-body{
  background: rgba(50, 50, 50, 1);
  color: white;
}
.faqs-section .accordion-body {
  background: rgba(50, 50, 50, 1);
  color: white;
  border-radius: 17px;
  border-top-left-radius: unset !important;
  border-top-right-radius: unset;
 padding: 20px;
 }
 .accord-div {
  padding: 40px 0px 0px 0px;
}
.faqs-section .accordion-item {
  border: 0px !important;
  border-radius: 20px !important;
}
button.accordion-button.collapsed {
  border-radius: 17px !important;
}
.faqs-section .accordion-button::after{
display: none;
}
.accordion-button{
  justify-content: space-between !important;
}
.faqs-section .accordion svg {
  background: white;
  color: black;
  height: 44px;
  width: 44px;
  border-radius: 50%;
  padding: 15px;
}
/* case study ends here  */
/* case study hero section starts */
.case-hero-section {
  padding: 180px 0px 70px 0px;
}
.case-hero-section h1 {
  font-size: 70px;
  font-weight: 700;
  color: white;
  margin-bottom: 20px;
}
.case-study-page section.case-study-section h2 {
  display: none;
}
/* case study hero section ends */
/* Global Styles */
body {
  font-family: 'Poppins', sans-serif;
  color: #fff;
  background-color: #0d0d0d;
}

a {
  color: #00d4ff;
}

a:hover {
  color: #fff;
}



.navbar .nav-link {
  color: #fff;
}

.navbar .nav-link:hover {
  color: #00d4ff;
}

.navbar-brand {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
}

.btn1 svg,
.btn svg {
  transform: rotate(-35deg);
}


body .btn1:hover {
  background-color: #32E0AE !important;
  transition: .9s;
  color: #fff;
  animation: button-hover 1s infinite  alternate ;
 
}

body .btn{
  background-color: #32E0AE;
  color: #003756;
  font-size: 15px;
  letter-spacing: 0px;
  text-transform: capitalize;
  font-weight: 600;
  border-radius: 30px !important;
  padding: 10px 20px;
  border: 0px;
}
body .btn1 {
  background-color: #FFC93E !important;
  color: #000 !important;
  font-weight: bold !important;
  font-size: 15px;
  letter-spacing: 0px;
  text-transform: capitalize;
  border-radius: 30px !important;
  padding: 10px 20px;
  border: 0px;
}

body .btn:hover {
  background-color: #ffc720 !important;
  transition: .9s;
  color: #fff;
  animation: button-hover 1s infinite  alternate ;
}


/* Hero Section */
.hero-section {
  /* height: 100vh; */
  display: flex;
  align-items: center;
  color: #fff;
}

.hero-content {
  padding-left: 50px;
  padding-right: 50px;
}

h1 {
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.2;
}

.highlight-text {
  color: #00d4ff;
}

p {
  font-size: 1.2rem;
  color: #bfbfbf;
}

.btn-hero {
  background-color: #ffd700;
  color: #000;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-bottom: 150px;
}

.statistics {
  font-size: 1.2rem;
  /* margin-top: 30px; */
  color: #bfbfbf;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: 600;
  display: block;
}

.stat-desc {
  color: #bfbfbf;
}

.phone-mockup {
  text-align: center;
  padding: 50px 0;
}

.phone-mockup img {
  /* height: auto; */
  width: 76%;
  margin-top: 126px;
  margin-right: 99px;
  animation: updown  1s infinite  alternate;
}
@keyframes updown{
0% {
  transform: translateY(0);
}
100% {
  transform: translateY(-10px);
}
}
/* Services Section */
.service-hero-section {
  padding: 190px 0px 70px 0px;
}
.service-hero-section h1 {
  color: white;
  font-size: 70px;
  font-weight: 700;
}
.services-section {
  padding: 100px 50px;

}

.services-section h2 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 50px;
  text-align: center;
}

.service-box h4 {
  color: #32e0ae;
  font-weight: bold;
  font-size: 21px;
  line-height: 31px;
}

.service-box p {
  font-size: 1rem;
  color: #bfbfbf;
}

/* More sections here... */
.centerhero {
  padding: 164px 0px 0 243px;
  text-align: left !important;
  margin-top: 100px !important;
  font-size: 100px !important;
}

.centerhero h1 {

  color: #FFFFFF;

}

.development {

  color: #32E0AE !important;
}

.home {
  background-image: url(./images/body.png);
  background-size: cover;
}

/* AWARDS SECTION */
/* src/components/AwardsSection.css */
.awards-section {
  padding: 100px 0;

  text-align: center;
}

section.awards-section .section-heading {
  color: #32E0AE;
}

.section-subheading {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 10px;
}

.section-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: #32E0AE;
  margin-bottom: 20px;
}

.section-description {
  font-size: 1.1rem;
  color: #fff;
  margin-bottom: 50px;
}

.award-box:hover {
  background-color: #00d4ff;
  color: #000;
}

.award-image {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.award-box h4 {
  font-size: 19px;
  color: #003756;
  margin-bottom: 10px;
  line-height: 25px;
  font-weight: bold;
}

.award-box p {
  font-size: 1rem;
  color: #000000 !important;
}

.case-study-section {
  padding: 100px 0;

}

.section-heading {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
  margin-bottom: 50px;
}

.case-study-box h4 {
  font-size: 26px;
  font-weight: bold;
  color: #fff;
  line-height: 36px;
}

.case-study-box p {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 20px;
}

.case-study-image img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 20px;
}

.case-study-buttons {
  display: flex;
  gap: 10px;
}

.case-study-buttons button {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
}

/* src/components/EmpowerSection.css */
.empower-section {
  padding: 100px 0;
}

.single-image-wrapper img {
  border-radius: 12px;
  max-width: 100%;
  /* Ensures responsiveness */
}

.section-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: #00d4ff;
  margin-bottom: 20px;
}



.buttons .btn {
  padding: 10px 30px;
  font-size: 1rem;
  border-radius: 5px;
}

.buttons .btn-warning {
  background-color: #ffd700;
  color: #000;
  border: none;
}

.buttons .btn-success {
  background-color: #32e0ae;
  color: #000;
  border: none;
}

.buttons .btn:hover {
  opacity: 0.9;
}

@media (max-width: 768px) {
  .empower-section {
    text-align: center;
  }

  .single-image-wrapper {
    margin-bottom: 40px;
  }

  .section-heading {
    font-size: 2rem;
  }

  .section-description {
    font-size: 1rem;
  }

  .buttons .btn {
    font-size: 0.9rem;
  }
}


.section-heading {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: left;
  color: #fff;
  margin-bottom: 50px;
}

.service-box p {
  font-size: 1rem;
  color: #bfbfbf;
  min-height: 110px;
}

.icon-wrapper {
  margin-bottom: 20px;
}

/* services starts here */
.ios-app-development .row {
  align-items: center;
}
.services-page{
background-image: url('./images/services-body.webp');
background-size: cover; 
}
.revolution-sec {
  padding: 30px 0px;
}
.revolution-sec h2 {
  color:#32E0AE;
}
.revolution-sec p {
  background-image: linear-gradient(360deg, rgba(255, 255, 255, 0.3) 44.5%, #FFFFFF 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.revolution-image-wrapper img {
  width: 70%;
  animation: updown  1s infinite  alternate;
}
.revolution-image-wrapper  {
 display: flex;
 justify-content: flex-end;
 align-items: center;
}
.revolution-sec .row {
  align-items: center !important;
} 
.numbers-section {
  padding:50px 0px 0px 0px;
}
.numbers-section .row {
  border-bottom: 1px solid rgba(255, 255, 255, 1);
  padding: 0px 0px 40px 0px;
}
.ios-app-image-wrapper img {
  width: 100%;
}
.ios-app-development {
  padding: 80px 0px;
}
.ios-app-development h2 {
  color: #32E0AE;
}
.ios-app-development h4 {
  color: #32E0AE;
}
.ios-app-development p {
  background-image: linear-gradient(360deg, rgba(255, 255, 255, 0.3) 44.5%, #FFFFFF 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.tech-stack .tech-p {
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.3) 44.5%, #FFFFFF 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.tech-stack-tabs {
  color: white;
  display: flex;
  gap: 60px;
  align-items: end;
  justify-content: flex-start;
}
.tech-stack-tabs-container {
  padding: 20px 0px;
}
.tech-stack-tabs-container .nav-tabs {
  border: unset;
}
.tech-stack-tabs-container .nav-item a.nav-link.active {
  background: transparent;
  color: rgb(50, 224, 174);
  border: unset;
}
.tech-stack-tabs-container .nav-item a.nav-link {
  color:white;
}
.tech-stack-tabs-container .nav-tabs .nav-link{
  border: unset !important;
}
.tech-stack-image {
  padding: 50px 0px;
  border: 1px solid rgba(255, 255, 255, 1);
  border-left: unset;
  border-right: unset;
}
.numbers-box h2 {
  font-family: 'Poppins';
  font-size: 134px;
  font-weight: 700;
  line-height: 54px;
  text-align: center;
  color: rgba(50, 224, 174, 0.2);
  margin-bottom: 30px;
}
.straight-line-2 {
  border-left: 1px solid rgba(255, 255, 255, 1);
  height: 65px;
  display: table;
  margin: auto;
  margin-bottom: 50px;
}
.numbers-box p {
  color: white;
  font-size: 16px;
}
.tech-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  background: white;
  padding: 30px 20px;
  border-radius: 20px;
}
.tech-image {
  max-width: 100%;
  height: auto;
  display: block;
}

.tech-label {
  margin-top: 10px;
  color: white; 
}
.what-makes-image-wrapper img {
  width: 80%;
}

.tech-stack-image {
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.what-makes-section h2.section-heading {
  color: #32E0AE;
}
.what-makes-section p{
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.3) 44.5%, #FFFFFF 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.what-makes-section {
  padding: 50px 0px;
}
.what-makes-section .row {
  align-items: center;
}

/* services ends here */
/* contact page starts here */
.contact-page{
  background-image: url('./images/contactbg.webp');
  background-size: cover;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1); 
}

.contact-form-section {
  padding: 180px 0px 100px 0px;
}
.info-box h3 {
  color: rgba(0, 55, 86, 1);  
  font-size: 38px;
  font-weight: 700;
  line-height: 57px;
}
.info-email p {
  color: rgba(255, 255, 255, 1) !important  ;

}
.info-email {
  padding: 20px 0px 0px 0px;
}
.contact-form-section h1 {
  font-size: 70px;
  font-weight: 700;
  margin-bottom: 80px;
}
.contact-form-section .col-lg-6 form .form-div {
  display: flex;
  padding: 5px 0px;
  gap: 10px;
  align-items: end;
} 
.contact-form-section .col-lg-6 form .form-div input {
  background: transparent;
  border: unset;
}
.contact-form-section .col-lg-6 form .form-div input {
  background: transparent;
  border: unset;
  border-bottom: 1px solid rgba(103, 99, 99, 1);
  border-radius: unset;
  color: white;
}
.contact-form-section .col-lg-6 form .form-div label {
  padding: 0;
  margin: 0;
}
.contact-form-section form {
  padding: 20px 30px;
}
.contact-form-section .col-lg-6 form textarea {
  background: transparent;
  border: unset;
  border-bottom: 1px solid rgba(103, 99, 99, 1);
  border-radius: 0;
  color: white;
}
.contact-form-section .col-lg-6 form .form-div-2 {
  margin-top: 30px;
}
.contact-form-section .col-lg-6 form .form-div input:focus {
  box-shadow: unset;
}
.contact-form-section .col-lg-6 form .form-div-2 textarea:focus {
  box-shadow: unset;
}
.info-box p {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: rgba(28, 28, 28, 1);
}
.info-box {
  background: rgba(50, 224, 174, 1);
  color: white !important;
  padding: 40px 30px;
  border-radius: 20px;
}
/* contact page ends here */
/* Responsive Styling */
@media (max-width: 768px) {
  .section-heading {
    font-size: 2rem;
  }

  .service-box h4 {
    font-size: 1.3rem;
  }

  .service-box p {
    font-size: 0.9rem;
  }

  .service-icon {
    width: 60px;
  }
}

/* src/App.css */

/* Stats Section */
.stats-section {
  padding: 120px 0 30px 0;
  background: none;
}

.stat-value {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
}

.stat-label {
  font-size: 1rem;
  color: #32E0AE !important;
}

@media (max-width: 768px) {
  .stat-value {
    font-size: 2rem;
  }

  .stat-label {
    font-size: 0.9rem;
  }
}

/* src/components/WhyChooseUs.css */
.why-choose-us-section {
  padding: 100px 0;

}

.section-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
}

.feature-list {
  list-style: none;
  padding: 0;
}

.feature-list li {
  margin-bottom: 20px;
}

.feature-list h4 {
  font-size: 30px;
  color: #32E0AE;
  margin: 8px 0;
  font-weight: bold;
}

.feature-list p {
  color: #bfbfbf;
}

.image-placeholder {
  width: 100%;
  height: auto;

}

/* Button styling */
button {
  background-color: #ffd700;
  color: #000;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
}

button:hover {
  opacity: 0.9;
}

section.empower-section .section-heading {
  color: #32E0AE;
}

/* footer */
footer.py-5 h5 {
  color: #32E0AE;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 10px;
}

footer.py-5 a {
  color: #fff;
  text-decoration: unset;
}

footer.py-5 ul li a {
  font-size: 15px;
  line-height: 41px;
  transition: .9s;
}

footer.py-5 ul li a:hover {
  color: #2ae0ae;
}

footer.py-5 ul li {
  color: #fff;
}

footer.py-5 p.mb-0 {
  font-size: 15px;
  color: #fff;
}
.social-icons a svg {
  background: #32e0ae;
  width: 50px;
  height: 50px;
  padding: 14px 0px;
  border-radius: 30px;
  transition: .9s;
}

.social-icons a svg:hover {
  background: #000;
}
/* about sec START */
.about-page{
  background-image: url('./images/services-body.webp');
  background-size: cover;  
}
.about-hero-section {
  padding: 180px 0px 110px 0px;
}
.about-hero-people-image{
  background-image: url('./images/about-hero-image-\(2\).png');
  background-size: contain;
  background-repeat: no-repeat;
padding: 310px;
}
.about-history-section {
  padding: 50px 0px;
}


.about-hero-section h1 {
  text-transform: capitalize;
  font-size: 70px;
  font-weight: 700;
  color: white;
}
.about-history-section h2 {
  font-size: 3rem;
  font-weight: 600;
  color: white;
}
h3.history-heading {
  font-size: 30px;
  color: #32E0AE;
  margin: 8px 0;
  font-weight: bold;
}
.history-section p {
  background-image: linear-gradient(360deg, rgba(255, 255, 255, 0.3) 44.5%, #FFFFFF 100%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
/* about sec END */
/* responsive starts here */
@media only screen and (max-width:1280px) {
  .centerhero {
    padding: 114px 0px 0 243px;
    margin-top: 50px !important;
  }
  .service-box {
    height: 310px;
}
section.testimonials-section .slick-next {
  bottom: 220px;
}
.empower-section {
  padding: 50px 0;
}
.services-section {
  padding: 50px 50px;
}
.why-choose-us-section {
  padding: 50px 0;
}
.case-study-section {
  padding: 50px 0;
}
.awards-section {
  padding: 50px 0;
}
.testimonials-section {
  padding: 50px 0 40px 0;
}
.contact-section {
  padding: 50px 0;
}
form .name {
  width: 30% !important;
}
form .iam {
  width: 35% !important;
}
form .get-in-touch {
  width: 40% !important;
}
form .start-date {
  width: 20%;
}
.about-hero-section {
  padding: 170px 0px 70px 0px;
}
}
@media only screen and (max-width: 600px) {
  section.hero-section .hero-content.container .centerhero {
    padding: 0;
    margin-left: 0;
}
html, body {
    overflow-x: hidden;
}
.home {
    background: #1e192a !important;
}
section.services-section h2 {
    font-size: 24px;
    line-height: 34px;
}

section.services-section {
    padding: 30px 0px;
}
.reach-image {
    margin: 0 auto;
    display: table;
    text-align: center;
}
}
@media only screen and (max-width:480px) {
  section.stats-section .row :nth-child(1) {
    border-left: unset;
}
section.stats-section .row :nth-child(3) {
  border: unset;
}
section.stats-section .row :nth-child(4) {
  border-bottom: unset;
}
  footer ul.list-unstyled {
    text-align: center;
}
footer.py-5 h5 {
  text-align: center;
}
.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

section.stats-section .col-md-3 {
  border-left: 1px solid #ffc93e;
  border-bottom: 1px solid #ffc93e;
}
  h1,h2,h3,h4,p{
    text-align: center !important;
  }
  section.hero-section .hero-content.container .centerhero {
    padding: 70px 0px 0 0px;
    margin-left: 0px;
}
.hero-section h1 {
  font-size: 40px;
  font-weight: 700;
}
.phone-mockup img {
  /* height: auto; */
  width: 60%;
  margin-top: 0px;
  margin-right: 0;
}
.phone-mockup {
  padding: 20px 0;
}
.stats-section {
  padding: 50px 0 30px 0;
}
section.hero-section button.btn1 {
  display: table;
  margin: 0 auto;
}
div#basic-navbar-nav {
  background: #000000cc;
  text-align: center;
}
nav .container {
  padding: 0;
}
.hero-section p {
  font-size: 16px;
}
.services-section {
  padding: 50px 20px;
}
.why-choose-us-section h2 {
  font-size: 35px;
}
.feature-list h4 {
  font-size: 25px;
}
section.why-choose-us-section button.btn1 {
  display: table;
  margin: 0 auto;
}
.case-study-buttons button {
  margin: 5px 0;
}
.case-study-buttons {
  margin: 0 auto;
  display: table;
  text-align: center;
}
p br{
  display: none;
}
section.testimonials-section h2 {
  text-align: center !important;
}
section.testimonials-section .slick-next {
  bottom: -100px;
  top: unset;
  right: 80px;
}
section.testimonials-section .slick-prev {
  left: -5px;
  z-index: 1;
  top: unset;
  bottom: -75px;
  left: 80px;
}
.testimonial-box {
  margin: 0 0px;
  padding: 15px;
}
.clients-image img {
  height: 70px;
  width: 70px;
  margin-bottom: 10px;
}
.clients-image {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
}
.stars {
  justify-content: center;
}
.clients-sec {
  display: block;
  text-align: center;
}
.contact-section {
  padding: 100px 0 50px 0;
}
.contact-section h2 {
  font-size: 58px;
  font-weight: 700;
  line-height: 64px;
  margin-bottom: 20px;
}
section.contact-section h4 br{
  display: none;
}
form .name {
  width: 100% !important;
}
form .iam {
  width: 100% !important;
}
form .get-in-touch {
  width: 100% !important;
}
form .start-date {
  width: 100%;
}
section.contact-section .col-md-6 div {
  display: block;
  width: auto;
 margin-bottom: 20px;
 }
.contact-section .form-control {
  width: 100% !important;
}
.custom-form .custom-button {
  display: table;
  margin: 0 auto;
}
.form-text.mt-3 p {
  font-size: 16px;
  line-height: 25px;
}
.about-hero-section h1 {
  font-size: 40px;
}
.about-hero-section {
  padding: 130px 0px 70px 0px;
}
.about-hero-people-image {
  padding: 80px;
}
.vision-box {
  padding: 0px 0px 0px 0px;
}
.vision-box h2.section-heading{
 display: table;
 margin: 0 auto;
 margin-bottom: 10px;
}
.vision-row-2 {
  flex-direction: column;
}
.vision-row-2 .vision-counter {
  margin-bottom: 20px;
}
a.navbar-brand img {
  width: 150px;
}
.about-history-section .row{
  flex-direction: column-reverse;
}
.years {
  margin-top: 10px;
  display: flex;
}
.years p {
  padding: 5px;
}
.about-history-section .col-lg-1 {
  order: 1;
}
.arrows {
  align-items: center;
}
.respect-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-hero-section h1 {
  font-size: 40px;
}
.service-hero-section {
  padding: 140px 0px 70px 0px;
}
.revolution-image-wrapper {
  justify-content: center;
  margin-top: 20px;
}
.tech-stack-image {
  padding: 50px 50px;
}
.expertise-section {
  padding: 30px 0px;
}
.expert-box h4 {
  font-size: 20px;
}
.expert-row-1.row .expert-box.text-center {
  padding: 20px 10px 10px 20px;
}
.expert-row-2.row .expert-box.text-center {
  padding: 20px 20px 10px 20px;
}
.tech-stack-tabs-container .nav-item a.nav-link {
  padding: 10px 8px !important;
}
.center-button {
  text-align: center;
}
.service-hero-section h1 br {
  display: none;
}
.case-hero-section h1 {
  font-size: 40px;
}
.contact-form-section h1 {
  font-size: 40px;
}
}
/* responsive ends here */
.vision-section {
  padding: 20px;
}

.vision-box {
  margin-bottom: 20px;
}

.vision-row-2 {
  margin: 20px 0;
}

.vision-counter {
  text-align: center;
}

.section-heading {
  font-size: 2rem;
}

@media (max-width: 768px) {
  .section-heading {
      font-size: 1.8rem;
  }
}

@media (max-width: 360px) {
  .vision-section {
      padding: 10px;
  }

  .section-heading {
      font-size: 1.5rem;
  }

}

.my-custom {
  margin: 0 auto;
  display: table;
}